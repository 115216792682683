@import "../../sass/grid";

.projects {
  max-width: 1425px;
  width: 100%;
  margin: auto;
  margin-top: 2rem;
  .col-lg-6 {
    padding: 0 45px;
  }
  .project-card {
    margin-bottom: 140px;
    position: relative;

    @include sm {
      margin-bottom: 70px;
    }

    .img-wrapper {
      cursor: pointer;
    }
    h4 {
      font-weight: 400;
    }
    &:hover {
      .card-details {
        box-shadow: 0 0 17px 0 rgba(0, 0, 0, 1);
      }
    }

    .card-details {
      cursor: pointer;
      overflow: hidden;
      width: 60%;
      padding: 25px;
      margin: 6px;
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: var(--white);
      box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);

      h4 {
        font-weight: 500;
      }
    }

    * {
      transition: all 0.3s ease-out;
    }
  }
}
.subheader-wrapper {
  background-color: var(--white);
  .projects-subheader {
    max-width: 1425px;
    margin: auto;
    padding: 60px 45px 50px;
  }
  .sub-title {
    color: var(--black2);
    font-weight: 300;
    font-size: 1.3rem;
    padding-bottom: 1.2em;
  }
  .sub-info {
    color: var(--blue);
    font-size: 1.6rem;
  }
}
