@import "../../sass/grid";

.ref-container {
  max-width: 1425px;
  margin: auto;
  margin-top: 2rem;

  .col-lg-2 {
    margin-bottom: 15px;
  }

  @include sm {
    .col-sm-12 {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
  }
}

.reference_img {
  box-shadow: 0 4px 10px 0 rgba(68, 68, 70, 0.2);
  width: 100%;
  height: 100%;
  max-width: 300px;
  object-fit: contain;
  transition: transform 0.5s ease;

  &:hover {
    z-index: 2;
    transform: scale(1.1);
  }
}
