@media (max-width: 767px) {
  .navbar-expand-md {
    flex-direction: row-reverse;
  }
}

.header {
  padding: 4px 40px;
  background: var(--white);
  border-radius: 0;
  margin: 0;
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.3);
}
.navbar-nav {
  .nav-link {
    color: black !important;
    padding: 15px 25px !important;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    &:before {
      left: 20px;
      bottom: 8px;
      width: 75%;
      height: 2px;
      content: "";
      position: absolute;
      background: var(--blue);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.4s ease;
    }
    &:hover {
      &:before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    &--active {
      &:before {
        left: 20px;
        bottom: 8px;
        width: 75%;
        height: 2px;
        content: "";
        position: absolute;
        background: #0c3179;
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 0.4s ease;
      }
    }
  }
}

.dropdown-item {
  padding: 0;
  a {
    width: 100%;
    display: inline-block;
    padding: 0.25rem 1.5rem;
    font-size: 14px;
    color: var(--black);
  }

  &:active {
    background-color: unset;
  }
}
