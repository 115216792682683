.home {
  .slider-caption {
    position: absolute;
    left: 0;
    top: 85%;
    right: 0;
    padding: 0 15px 15px 30px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99;
    max-width: 455px;
    transform: translateY(-85%);
    transition: all 800ms ease;
  }
  @media (max-width: 767px) {
    .slider-caption {
      top: 70% !important;
      transform: translateY(-70%) !important;
    }
  }
}

.caption-title {
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 20px;
  color: var(--blue);
}
