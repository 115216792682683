@import "../../sass/grid";

.about {
  height: 100%;
  position: relative;

  .slider-caption {
    position: absolute;
    left: 0;
    top: 85%;
    right: 0;
    padding: 0 15px 15px 15px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99;
    max-width: 800px;
    transform: translateY(-85%);
    transition: all 800ms ease;
  }
}

@include sm {
  .about {
    .slider-caption {
      height: 100%;
      overflow: scroll;
      top: 0;
      transform: unset;
      padding-top: 30px;
    }

    .bg-wrapper {
      top: 0;
    }

    .caption-title {
      margin-top: 0;
    }
  }
}
