@import "../sass/grid";

.card-det-container {
  max-width: 1425px;
  width: 100%;
  margin: auto;
  padding: 0 90px;
  margin-bottom: 2rem;
  @include sm {
    padding: 20px;
  }
  .card-det-content-wrapper {
    padding: 30px;
  }
}

.card-det-wrapper {
  .slick-slider {
    overflow: hidden;
  }
  .slick-slide {
    cursor: pointer;
    width: 500px !important;
    height: 400px;
    position: relative;
    margin-right: 2px;
    @include sm {
      max-width: 300px !important;
    }

    .card-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .card-bg-img {
    min-width: 100%;
  }

  .lg-img {
    object-fit: cover;
    width: 100%;
    height: 500px;
  }

  .content-row {
    @include md {
      flex-wrap: unset;
    }
  }
}

.card-img-wrapper {
  position: relative;
  display: block !important;

  &:focus {
    outline: none;
  }
}

.card-det-col-dir {
  flex-direction: column;

  .content-title {
    margin-bottom: 3rem;
    h4 {
      font-weight: 500;
      font-size: 1.1rem;
    }
    p {
      font-weight: 400;
      font-size: 1rem;
    }
  }
}

.card-det-container-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
}

.bottom-slider {
  .fa-chevron-circle-right {
    position: absolute;
    color: var(--black);
    bottom: 50%;
    right: 10px;
    z-index: 5;
    transform: translate(0, 50%);
    &.slick-disabled {
      opacity: 0.1;
    }
  }
  .fa-chevron-circle-left {
    position: absolute;
    color: var(--black);
    bottom: 50%;
    transform: translate(0, 50%);
    left: 10px;
    z-index: 5;
    &.slick-disabled {
      opacity: 0.5;
    }
  }
}

.content-line {
  h4 {
    word-wrap: break-word !important;
  }
}

.slider-presentation {
  padding-left: 0 !important;
  @media (min-width: 576px) {
    max-width: unset;
    margin: unset;
    .modal-dialog {
      max-width: unset;
      margin: unset;
    }
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .modal-content {
    border: unset;
    background: transparent;
    width: 50vw;
    height: 80vh;
  }
  .presentation-img {
    width: 50vw;
    height: 80vh;
    object-fit: contain;
  }
}
