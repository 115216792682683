@import "../../sass/grid";

.bg-wrapper {
  @include sm {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 68px;
    z-index: -1;
  }
  .img-wrapper {
    height: calc(100vh - 68px);
    position: relative;
    display: block !important;
    .bg-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
