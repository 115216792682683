@import "../../sass/grid";

.cetificates-container {
  max-width: 1425px;
  margin: auto;
  margin-top: 2rem;

  @include sm {
    .col-sm-12 {
      margin-bottom: 15px;
    }
  }
}

.certificate_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
