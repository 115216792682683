@import "../../sass/card";

:root {
  --blue: #0c3179;
  --black: #000;
  --black2: #222222;
  --white: #fff;
}

.App {
  padding-top: 68px;
  position: relative;
  height: 100%;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--black);
}

.spinner-position {
  position: absolute;
  right: calc(50% - 175px);
  bottom: calc(50% - 175px);
  width: 350px;
  height: 350px;
}

.img-wrapper {
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }
}

.card-img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}
