@import "../../sass/grid";

.contact {
  .slider-caption {
    max-width: 420px;
    right: 0;
    width: 420px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 15px 15px 15px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99;
    max-width: 455px;
    transition: all 800ms ease;
    width: 100vw;

    &.sec-slider-caption {
      left: 0%;
      top: 50%;
      @include md {
        transform: unset !important;
        top: 58px !important;
      }
      @include sm {
        position: static;
        transform: none;
      }
    }
    &.first-slider-caption {
      right: 0;
      top: 50%;
      @include md {
        transform: unset !important;
        bottom: 0 !important;
      }
      @include sm {
        position: static;
        transform: none;
      }
    }
    .caption-title {
      color: var(--blue);
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}
